@import "./scss/base/vars";
@import "./scss/base/mixins";
@import "./global.scss";

@font-face {
  font-family: "Lato";
  src: local("Lato"), url(./assets/fonts/Lato/Lato-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Regular";
  src: local("Lato"), url(./assets/fonts/Lato/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Semi";
  src: local("Lato"), url(./assets/fonts/Lato/LatoSemibold.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./assets/fonts/Roboto/RobotoCondensed-Regular.ttf) format("truetype");
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  // position: relative;
  overflow-x: hidden;
  background: cssVar(bg);
  // @include font(cssVar($fontFamily));
}

.p-toolbar {
  // margin: 1em;
  // margin-top: 24px;
  padding: 0em;
  background: none;
  border: none;

  .head {
    margin: 1em;
    margin-top: 24px;
    margin-bottom: 0px;
    font-size: 22px;
  }
}

.p-datatable {
  .p-datatable-header {
    background: none;
    border: none;

    .tab-head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .add-txt {
        width: 100%;
        font-size: 24px;
        font-weight: 600;
        border-radius: 20px;

        .p-button-label {
          font-size: 24px;
          font-weight: 600;
        }
      }
    }
  }

  .p-datatable-thead {
    tr {
      height: 35px;

      th {
        // background-color: cssVar(table-head);
        // color: cssVar(light);
        i {
          // color: cssVar(light) !important;
        }

        &:active,
        &:hover,
        &:focus {
          // background-color: cssVar(table-head) !important;
          // color: cssVar(light) !important;
          i {
            // color: cssVar(light) !important;
          }
        }
      }
    }
  }
}
///for scripts-assign advs
.advtime {
  input {
      &.p-inputtext {
          background-color: #fff;
          border-radius: 6px;
          opacity: 1;
      }
  }
}
.menuToggler {
  // padding: 6px;
  cursor: pointer;
  // border-radius: 50%;
  display: inline-block;
  border: 1px solid rgba(#{cssVar(dark, $color)}, 0.1);
  // width: 30px;
  // height: 30px;
  line-height: 0;
}

//commented due to design issue in calender view .If we provide padding here it will effect all the cards that we use

.card {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 6px;
  padding-top: 0px !important;
  border: #dee4f5;
  box-shadow: unset;
  border: 1px solid rgba(112, 112, 112, 0.2);
}

.card-padding {
  margin: 1em;
  padding: 1em;
  border-radius: 6px;
  margin-top: 0;
  padding-bottom: 0;
  &.legalserver{
    padding-bottom: 1em;

  }

}

.home {
  .card {
    border: 1px solid rgba(112, 112, 112, 0.5);
  }
}

.p-text-right {
  display: flex;
  justify-content: center;

  .p-button-text {
    padding-right: 20px;
  }
}

.grid .field {
  // margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

.field > label {
  // font-weight: 600;
  display: inline-block;
  margin-bottom: 0.5rem;
}

// .p-button-label {
//   font-size: 16px;
// }
.single {
  .card,
  .calender-card {
    border-radius: 6px;

    .inputarea,
    input,
    textarea {
      border-radius: 6px;
      font-size: 16px;
      color: #0000;
      // border-color:#8D8CB2 ;

      .p-dropdown,
      input {
        font-size: 16px;
        width: 100%;
        border-radius: 6px;
        color: #000;
        // border-color:#8D8CB2 ;
      }
    }

    .p-inputtext {
      @include font($fontFamily);
      font-size: 16px;
      color: #000;
    }
  }
}

.footer {
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding: 10px;
  font-size: 12px;
  position: fixed;
  text-align: center;
  color: cssVar(footer-text);
  background-color: cssVar(footer-bg);
}

.p-message {
  margin: 0.2em;

  .p-message-wrapper {
    padding: 0.7rem 1.5rem;
  }
}

.layout-wrapper {
  header.header {
    width: 100%;

    .menuToggler {
      .leftIcon {
        display: none;
      }

      .rightIcon {
        display: inline-block;
      }
    }
  }

  &.menuOpened {
    .header.header {
      // width: calc(100% - #{$sideBarWidth});
      .menuToggler {
        .leftIcon {
          display: inline-block;
        }

        .rightIcon {
          display: none;
        }
      }
    }
  }

  background: #eff3f8;
}

.logo {
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  padding: 4px;
  position: sticky;
  text-align: center;
  background: cssVar(header-bg);

  img {
    width: auto;
    max-height: 60px;
  }
}

// Common css for buttons (submit,cancel,edit,delete,view icons)
.button-theme {
  color: white;
  background-color: #1d9add;
  border: none;

  &:hover {
    background-color: #1d9add;
  }
}

.button-theme-cl {
  color: #004488;
  background-color: #dde2ea;
  border: none;
}

// .button-theme:hover {
//   color: white;
//   background-color: #004488;
// }

// .button-theme-cl:hover {
//   color: white;
//   background-color: #DDE2EA;
// }

// td {
//   text-transform: capitalize;
// }

// td {
//   text-transform: capitalize;
// }

// Arjun -scss
// tab view
/* not selected */
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-color: white;
  background: #dee4f5;
  color: #004488;
  border-radius: 4px;
}

/* hover */
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #dee4f5;
  border-color: white;
  color: #004488;
}

/* selected */
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #0b109f;
  border-color: white;
  color: white;
}

.p-datatable .p-datatable-thead > tr > th {
  color: #ffffff;
  background: #0b109f;
  font-size: 14px;
}

th.p-element.p-sortable-column.p-highlight {
  color: #ffffff;
  background: #0b109f;
}

// breadcrumb card style/padding
.p-breadcrumb {
  background-color: #eef3f9;
  border-color: #eef3f9;
}

.breadcrumb-card {
  margin-left: 6px;
  margin-right: 10px;
}

.calender-card {
  margin-left: 15px;
  margin-right: 15px;

  .inputarea,
  input,
  textarea {
    border-radius: 6px;
    font-size: 16px;
    color: #000;

    .p-dropdown,
    input {
      width: 100%;
      border-radius: 6px;
      font-size: 16px;
      color: #000;
    }
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: #000;
}

.inner-tab-card {
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0 5px 7px -3px rgb(0 0 0 / 20%), 0 11px 17px 2px rgb(0 0 0 / 7%), 0 4px 20px 4px rgb(0 0 0 / 6%);
}

.p-tab {
  margin-left: 13px;
  margin-right: 13px;
  padding: 1px;

  &.script-tab {
    position: relative;

    .button {
      position: absolute;
      z-index: 1;
      right: 0px;
    }
  }

  .card {
    border-radius: 10px;
    // min-height: 100vh;
  }
}
.scripttextarea {
  .p-multiselect {
    .p-multiselect-label {
      font-size: 16px;
      &.p-placeholder {
        color: black;
      }
    }
  }
}

.button-theme-danger {
  color: white;
  background-color: #d5232a;
  border: none;
}

.p-dialog .p-dialog-header {
  height: 1px;
  color: #ffffff;
  background-color: #0b109f;

  padding: 30px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #ffffff;
  background-color: #d5232a;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:hover {
  color: #ffffff;
  background-color: #d5232a;
}
.calender {
  .p-dialog {
    .p-dialog-header {
      background: map-get($color, light);
      padding: 0;
    }
    .p-dialog-header-icons {
      position: relative;
      top: 30px;
      right: 20px;
    }
  }
}

.p-tabview .p-tabview-nav {
  border-radius: 10px;
}

.p-tabview .p-tabview-panels {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #0b109f;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.4em;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #e9ecef;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr:nth-child(even) {
  background: #eff1f5;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  // color: #495172;
  padding: 0rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > .rc > td {
  padding: 10px 0;
}

// step panel styles- Arjun
.p-steps {
  position: relative;
  margin-top: 14px;
}

.p-steps-item {
  position: relative;
  display: flex;
  justify-content: center;
  width: 0px;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: #eff1f5;
}

.p-steps .p-steps-item.p-highlight .p-menuitem-link {
  background: #eff3f8;
  width: 100%;
}

// steps number

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  // color: #DEE4F5;
  border: 3px solid #0b109f;
  background: #DEE4F5;
  margin-top: 15px;
}

// .p-steps .p-steps-item.p-highlight .p-steps-number {
//   background: #0b109f;
//   color: #ffffff;
//   font-weight: bold;
//   border: 2px solid #ffffff;
//   margin-top: 15px;
// }



.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #0b109f;
  color: #FFFFFF;
  font-weight: bold;
  border: 2px solid #FFFFFF;
  margin-top: 10px;
  min-width: 2.8rem;
  height: 2.8rem;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: bolder;
  color: #495057;
  font-size: large;
  margin-bottom: 2px;
}


// .p-steps .p-steps-item.p-highlight .p-steps-number {
//   background: #0b109f;
//   color: #ffffff;
//   font-weight: bold;
//   border: 2px solid #ffffff;
//   margin-top: 15px;
// }




// .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
//   color: #DEE4F5;
//   background-color: #DEE4F5;
// }

.p-steps-item.p-steps-current .p-menuitem-link {
  cursor: pointer;
}

.p-steps-item.ng-star-inserted.p-highlight.p-steps-current {
  background-color: #0b109f;
}

.p-steps .p-steps-item:before {
  border: 2px solid #0b109f;
}

.p-element.p-steps-item.ng-star-inserted .p-highlight .p-steps-current {
  background-color: #0b109f;
}

// step panel styles close
// Arjun

// Basil & sarang

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  &.act-rgt-three {
    text-align: right;
    padding-right: 65px;
  }

  &.act-rgt {
    text-align: right;
    padding-right: 20px;
  }
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  &.act-rgt {
    display: flex;
    justify-content: end;
  }

  .act-rgt-inner {
    min-width: 110px;
    text-align: left;
  }

  .act-rgt-inner1 {
    min-width: 145px;
    text-align: left;
  }

  &.act-rgt-delete {
    text-align: right;
    padding-right: 58px;
  }

  &.act-rgt-left {
    text-align: right;
    padding-right: 74px;
  }
}

// validation css for star with red color
.validation-star {
  font-size: 17px;
  color: red;
  line-height: 0;
}

.dropdown {
  position: relative;

  &:hover,
  &:focus,
  &:active {
    .dropdown-menu {
      display: block;
    }
  }

  &-menu {
    @include notaList;
    top: 100%;
    z-index: 40;
    padding: 2px;
    display: none;
    min-width: 30px;
    left: -60px;
    position: absolute;
    color: cssVar(text);
    background: cssVar(light, $color);

    &,
    .nav-link {
      cursor: pointer;
      border-radius: 3px;
      @extend %animateFast;
    }

    .nav-link {
      width: 100%;
      display: block;
      text-align: left;
      padding: 15px 10px;
      color: cssVar(text);
      align-items: center;
      display: inline-flex;
      background: transparent;
      border: 0 none transparent;
      justify-content: flex-start;

      &:hover,
      &:focus,
      &:active {
        color: cssVar(write);
        background: cssVar(color);
      }
    }
  }

  .rc > td {
    padding: 10px 0 !important;
  }
}

// tab scss

.p-tabview-nav {
  display: flex;
  background: transparent !important;
  border: none !important;

  li {
    min-width: 70px;
    margin-right: 2px !important;

    a {
      justify-content: center;
      border-radius: 10px 10px 0 0 !important;
    }
  }
}

// search form label,button scss
.lbl {
  //  font-family: Lato-Regular;
  font-size: 14px;
  font-weight: 600;
  color: #8d8cb2;
}

///For version style inside scripts card//////
.script-card {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0px;
  left: 0px;
}

.script-version {
  padding: 3px 5px;
  border-top-left-radius: 10px;
  width: 50px;
  background-color: cornflowerblue;
  font-size: large;
  text-align: center;
  color: white;
}

// .vlue{
//   font-size: 14px;
//   font-family: Lato-Regular;
//   color: #222222;
// }

// .search-txt{
//   font-family: Lato-Semi;
//   font-size: 15px;
// }

// .btn-fmly{
//   font-family: Lato-Regular;
//   font-size: 15px;
// }

// .btn-mw{
//   min-width: 155px;
//   padding: 10px;
// }

// .nlbl{
//   font-family: Lato-Regular;
//   font-size: 14px;
//   color: #8D8CB2;
// }

.bold-select {
  .p-inputtext {
    @include font($fontFamily);
    font-size: 16px;
    color: #222222;
  }
}

// p-calendar {
//   span {
//     button {
//       background-color: #1d9add !important;
//     }
//   }
// }
.p-datepicker-buttonbar {
  button {
    background-color: #1d9add !important;
    color: white !important;
  }
}

.formgroup-inline .field > label,
.formgroup-inline .field-checkbox > label,
.formgroup-inline .field-radiobutton > label {
  color: #8d8cb2;
}
// .formgroup-inline .field>label,
// .formgroup-inline .field-checkbox>label,
// .formgroup-inline .field-radiobutton>label {
//   color: #8D8CB2;
// }

.p-inputtext[readonly],
p-dropdown.readOnly .p-dropdown {
  background-color: #F8F9F9;
  opacity: 0.5;
}

p-dropdown.readOnly .p-dropdown {
  .p-dropdown-clear-icon {
    display: none;
  }
}
.exceptionSituation{
  th{
  .sort{
    display: none;

    }

  }
}

.resClass{
  margin-left: -100px;
  margin-top: 20px;
}

